







































import { Component, Vue } from "vue-property-decorator";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import { TableOptions } from "../types";
import { Device, DevicesFilterEnum } from "../types/gqlGeneratedPrivate";
import { TablesNamespaces } from "../store/modules/tables";
import Modal from "../components/commons/Modal.vue";
import { EventBus } from "../enums/EventBus";
import { eventBus } from "../utils/eventBus";
import DeviceDeleteModal from "../components/app/devices/DeviceDeleteModal.vue";
import DeviceCreateModal from "../components/app/devices/DeviceCreateModal.vue";
import DeviceUpdateModal from "../components/app/devices/DeviceUpdateModal.vue";
import { Routes } from "../enums";

@Component({
  components: {
    AppLayout,
    Button,
    Modal,
    RoundButton,
    Table,
    TableHeader,
    ActionsWrapper,
    DeviceDeleteModal,
    DeviceCreateModal,
    DeviceUpdateModal,
  },
})
export default class DevicesPage extends Vue {
  private deviceName = "";

  Routes = Routes;

  tableOptions: TableOptions<Device, DevicesFilterEnum> = {
    defaultSortBy: ["number"],
    headers: [
      {
        text: "Číslo",
        sortable: true,
        align: "start",
        value: "number",
      },
      {
        text: "Název",
        sortable: true,
        align: "start",
        value: "name",
      },
      {
        text: "Laborant",
        sortable: true,
        align: "start",
        value: "laborer",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.DevicesTable,
  };

  deleteItem(device: Device): void {
    this.deviceName = device.name;

    eventBus.$emit(EventBus.DeviceDeleteModal, device);
  }

  addItem(): void {
    eventBus.$emit(EventBus.DeviceCreateModal);
  }

  updateItem(device: Device): void {
    eventBus.$emit(EventBus.DeviceUpdateModal, device);
  }
}
