var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TableHeader',{attrs:{"table-options":_vm.tableOptions,"title":"Zařízení"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('Button',{attrs:{"color":"secondary","outlined":""},on:{"click":_vm.addItem}},[_vm._v("Přidat")])]},proxy:true}])}),_c('div',{staticClass:"table-medium"},[_c('Table',{attrs:{"table-options":_vm.tableOptions},scopedSlots:_vm._u([{key:"number",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
            name: _vm.Routes.DeviceDetail,
            params: {
              id: item.id,
            },
          }}},[_c('span',[_vm._v(_vm._s(item.number))])])]}},{key:"actions",fn:function(ref){
          var item = ref.item;
return [_c('ActionsWrapper',[_c('RoundButton',{attrs:{"icon":"pencil"},on:{"click":function () { return _vm.updateItem(item); }}}),_c('RoundButton',{attrs:{"icon":"delete"},on:{"click":function () { return _vm.deleteItem(item); }}})],1)]}},{key:"laborer",fn:function(ref){
          var value = ref.value;
return [_vm._v(" "+_vm._s(value.firstname)+" "+_vm._s(value.surname)+" ")]}}])}),_c('DeviceDeleteModal',{attrs:{"device-name":_vm.deviceName}}),_c('DeviceCreateModal'),_c('DeviceUpdateModal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }