



















import { Component, Vue } from "vue-property-decorator";
import Modal from "../../commons/Modal.vue";
import { ValidationObserver } from "vee-validate";
import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import { api } from "../../../api";
import { EventBus } from "../../../enums";
import { eventBus } from "../../../utils/eventBus";
import {
  MutationCreateDeviceArgs,
  MutationUpdateDeviceArgs,
  DeviceFormFragment,
} from "../../../types/gqlGeneratedPrivate";
import DeviceForm from "./DeviceForm.vue";
import { apiClient } from "../../../utils/apiClient";
import { alerts } from "../../../utils";
import { Action } from "vuex-class";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { TableRefreshPayload } from "../../../types";

const emptyFormData: DeviceFormFragment = {
  number: "",
  name: "",
  note: "",
  laborerId: 0,
};

@Component({
  components: {
    Button,
    Modal,
    DeviceForm,
    TextField,
    ValidationObserver,
  },
})
export default class DeviceCreateModal extends Vue {
  isOpen = false;
  isSending = false;

  formData: DeviceFormFragment = {
    ...emptyFormData,
  };

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.DevicesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  created(): void {
    eventBus.$on(EventBus.DeviceCreateModal, () => {
      this.initForm();
      this.isOpen = true;
    });
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (valid) {
      this.sendForm(this.formData);
    }
  }

  async sendForm(formData: DeviceFormFragment): Promise<void> {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      MutationUpdateDeviceArgs,
      MutationCreateDeviceArgs
    >({
      ...api.devices.createDevice,
      variables: {
        input: formData,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("CREATE_DEVICE", "Uloženo");
      this.refreshTable({
        namespace: TablesNamespaces.DevicesTable,
      });
    }
    this.initForm();
    this.isOpen = false;
  }

  initForm(): void {
    this.formData = {
      ...emptyFormData,
    };
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
